import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from "react-bootstrap/Button";
import spainFlag from "../Assets/spain-flag.svg";
import britishFlag from "../Assets/british-flag.svg";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(() => {
    const savedLanguage = getFromLocalStorage('language');
    return savedLanguage || navigator.language.split('-')[0] || 'en'; // Toma el idioma del navegador o 'en' por defecto
  });

  useEffect(() => {
    i18n.changeLanguage(language);
    saveToLocalStorage('language', language);
  }, [language, i18n]);

  const changeLanguage = () => {
    console.log(language)
    if (language == 'es')
        setLanguage('en');
    else
        setLanguage('es')
  };

    return (
        <Button
            onClick={() => changeLanguage()}
            className="fork-btn-inner"
        >
            {
                language == 'en' &&
                <img
                    src={spainFlag}
                    alt="icon"
                    style={{ width: '20px' }}
                />
            }
            {
                language == 'es' &&
                <img
                    src={britishFlag}
                    alt="icon"
                    style={{ width: '20px' }}
                />
            }
        </Button>
    );
};

const saveToLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

const getFromLocalStorage = (key) => {
  const value = localStorage.getItem(key);
  return value ? JSON.parse(value) : null;
};

export default LanguageSwitcher;
