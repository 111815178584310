import React from "react";
import Typewriter from "typewriter-effect";
import { useTranslation } from 'react-i18next';

function Type() {

  const { t } = useTranslation();

  return (
    <Typewriter
      options={{
        strings: [
          t('software_engineer'),
          t('java_developer'),
          t('dotnet_developer'),
          t('learning_react'),
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 25,
      }}
    />
  );
}

export default Type;
