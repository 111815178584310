import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Archivos de traducción (puedes crear más para otros idiomas)
import translationEN from './locales/en/translation.json';
import translationES from './locales/es/translation.json';

i18n
  .use(initReactI18next) // Integración con React
  .init({
    resources: {
      en: {
        translation: translationEN
      },
      es: {
        translation: translationES
      }
    },
    lng: navigator.language || 'en', // Detecta el idioma del navegador
    fallbackLng: 'en', // Idioma por defecto si el navegador no está en la lista
    interpolation: {
      escapeValue: false // React ya se encarga de la sanitización
    }
  });

export default i18n;